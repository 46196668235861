<template>
  <div class="flex column mr-20">
    <div class="flex">
      <div class="box center pr-10">
        <i class="mdi mdi-notebook-outline"></i>
      </div>
      <div class="info box grow flex">
        <div class="name box grow flex column justify-center p-10">
          <div class="fullname fs-18 clickable danger-light-text"
            @click="onClickClient(item.customer.id)">
            <strong v-if="item.useStatus === 'booked'">{{item.customer.first}} {{item.customer.surname}}</strong>
            <s v-else-if="item.useStatus === 'used'">{{item.customer.first}} {{item.customer.surname}}</s>
          </div>
        </div>
        <div class="box grow flex column justify-space-around">
          <div class="box fs-14 hint-text">
            {{item.customer.email}}
          </div>
          <div class="box fs-14 hint-text">
            {{item.customer.phone}}
          </div>
          <div class="box fs-14">
            {{item.ticket.persons}} {{item.ticket.name}}
          </div>
        </div>
      </div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'BookRow',

  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },

  methods: {
    ...mapActions(['addWaiwerToBasket']),
    onClickClient (id) {
      this.addWaiwerToBasket({ code: id, isRemove: false })
    }
  }
}
</script>
