<template>
    <div class="flex column gaps">
        <div class="flex justify-space-between">
            <div class="box self-flex-end">
              <h2 class="mv-0">{{current.name}}, {{current.time}}</h2>
            </div>
            <div class="box">
              <el-button @click="$onCommandParams('home')">BACK</el-button>
            </div>
        </div>

        <el-divider class="mv-10 o-020"></el-divider>

        <div class="box flex gaps">
          <div class="box">
            <h4 class="mb-5">
              <strong class="danger-light-text pr-5">{{countBooked}}</strong>
              <s class="danger-light-text pr-5">{{countUsed}}</s>
              <span class="info-text pr-5">{{countBlue}}</span>
            </h4>
          </div>
          <div class="box">
            <h4 class="mb-5 o-050">Search: </h4>
          </div>
          <div class="box grow">
            <el-input class="box grow" ref="search" v-model="search"></el-input>
          </div>
        </div>

        <div class="box">
          <vue-scroll
            class="scroll-area flex"
            :settings="settings"
            :style="{height: scrollHeight + 'px'}">
            <div class="box grow">
              <app-book-row
                :item="b"
                v-for="(b, index) in filteredBooks" :key="index +'-book'"></app-book-row>
              <app-ticket-row
                :item="t"
                v-for="(t, index) in filteredTickets" :key="index +'-ticket'"></app-ticket-row>
            </div>
          </vue-scroll>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import AppBookRow from './components/BookRow'
import AppTicketRow from './components/TicketRow'

export default {
  name: 'SessionInfo',

  components: {
    AppBookRow,
    AppTicketRow
  },

  props: {
    members: {
      type: Array,
      default: function () {
        return []
      }
    },
    basket: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  data () {
    return {
      settings: {
        maxScrollbarLength: 60
      },
      search: ''
    }
  },

  computed: {
    ...mapState({
      innerHeight: state => state.nav.innerHeight
    }),
    ...mapGetters([
      'today',
      'todaySessions',
      'getTicketsBySessionIdx',
      'getBooksBySessionIdx'
    ]),
    current () {
      return this.todaySessions[this.$route.params.id] || {}
    },
    books () {
      let books = this.getBooksBySessionIdx[this.today + '-' + this.$route.params.id] || []
      let used = books.filter(b => b.useStatus === 'used')
      let booked = books.filter(b => b.useStatus === 'booked')
      return [...booked, ...used]
    },
    filteredBooks () {
      return this.books.filter(item => {
        return (this.search.length < 2 ||
         ((item.customer.first.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
         (item.customer.surname.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
         (item.customer.email.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
         (item.customer.phone.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)))
      })
    },
    tickets () {
      return this.getTicketsBySessionIdx[this.today + '-' + this.$route.params.id] || []
    },
    filteredTickets () {
      return this.tickets.filter(item => {
        return (this.search.length < 2 || (item.clientNames
          .some(n => n.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)) ||
          (item.hasOwnProperty('customerName') && item.customerName.toLowerCase().indexOf(this.search.toLowerCase()) !== -1))
      })
    },
    scrollHeight () {
      return this.innerHeight - 407 // 337
    },
    countBooked () {
      return this.books
        .filter(b => b.useStatus === 'booked')
        .reduce((out, item) => {
          out = out + item.ticket.persons
          return out
        }, 0)
    },
    countUsed () {
      return this.books
        .filter(b => b.useStatus === 'used')
        .reduce((out, item) => {
          out = out + item.ticket.persons
          return out
        }, 0)
    },
    countBlue () {
      return this.tickets.reduce((out, item) => {
        out = out + item.persons
        return out
      }, 0)
    }
  },

  methods: {
    searchFocus () {
      this.search = ''
      if (JSON.parse(localStorage.getItem('cursor'))) {
        this.$refs.search.focus()
      }
    }
  },
  activated: async function () {
    this.searchFocus()
  },
  watch: {
    clearInputHome: function (val, oldVal) {
      this.searchFocus()
    }
  }
}
</script>
