<template>
  <div class="flex column mr-20">
    <div class="flex">
      <div class="box center pr-10">
        <i class="mdi mdi-ticket"
          :class="[item.isBooking ? 'mdi-ticket-account' : 'mdi-ticket']"></i>
      </div>
      <div class="info box grow flex">
        <div class="name box grow flex column justify-center p-10">
          <div class="fullname" v-for="(c, index) in item.clientNames" :key="index +'-client'">
            <div class="info-text clickable" @click="onClickClient(index)">{{c}}</div>
          </div>
          <template v-if="item.persons - item.clientNames.length > 0">
            <div class="fullname" v-for="(c, idx) in (item.persons - item.clientNames.length)" :key="idx +'-no'">
              <div class="accent-text">No waiver</div>
            </div>
          </template>
        </div>
        <div class="box grow flex column justify-space-around">
          <div class="box fs-14">
            {{item.persons}} {{item.typeName}}
          </div>
          <div class="box fs-14"
            v-if="item.isBooking && item.hasOwnProperty('customerName')">
            <span class="success-light-text">{{item.customerName}}</span>
          </div>
        </div>
      </div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'BookRow',

  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },

  methods: {
    ...mapActions(['addWaiwerToBasket']),
    onClickClient (index) {
      this.addWaiwerToBasket({ code: this.item.clientIds[index], isRemove: false })
    }
  }
}
</script>
